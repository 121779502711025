import React from "react";
import styled from "styled-components";
import svgg from "../../../../Images/svg.svg";
import { primaryColor, secGradient } from "../../../../../GlobalStyles";

export default function Section2() {
  return (
    <Root>
      <div className="container-fluid">
        <div className="row">
          {/* <div className="col-lg-2 col-md-12 col-12"></div> */}
          <div
            className="col-lg-12 col-md-12 col-12 text_div"
            data-aos="fade-up"
          >
            <span>Why should </span>
            <span className="text">Laravel developers</span>{" "}
            <span>use Phanom Profеssionals?</span>
            <p className="mt-3">
              Higher engagement, lower cost: Offer higher engagement services
              for the benefit of a 50% cost reduction. Our comprehensive testing
              program assures that you are with experienced people who can
              deliver fast results.
            </p>
            <p>
              Global Brands believes: Join Phanom Profеssionals. Our team of
              professionals helps in giving you best services and in engaging
              excellence and growth in your business.
            </p>
          </div>
          {/* <div className="col-lg-2 col-md-12 col-12"></div> */}
        </div>

        <div className="row">
          {/* <div className="col-lg-2 col-md-12 col-12"></div> */}
          <div
            className="col-lg-12 col-md-12 col-12 text_div"
            data-aos="fade-up"
          >
            <span>Services Offered by </span>
            <span className="text">Our </span> <span>Laravel Developers</span>
          </div>
          {/* <div className="col-lg-2 col-md-12 col-12"></div> */}
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 col-12 block_main">
            <div className="block_div">
              <div>
                <img src={svgg} alt="img" />
              </div>
              <div className="tag_text">
                <p>Customized Laravel web applications</p>
                <span>
                  To provide global customers in diverse locations online access
                  with personalized web solutions
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 block_main">
            <div className="block_div">
              <div>
                <img src={svgg} alt="img" />
              </div>
              <div className="tag_text">
                <p>Laravel Integration & Maintenance</p>
                <span>
                  Our Laravel developers are always dedicated to managing and
                  maintaining online applications while adhering to best
                  practices.
                </span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-12 block_main">
            <div className="block_div">
              <div>
                <img src={svgg} alt="img" />
              </div>
              <div className="tag_text">
                <p>Laravel Migration Services</p>
                <span>
                  Expert Laravel programmers provide reliable migration services
                  to convert your business application from other
                  technologies/frameworks to Laravel.
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 block_main">
            <div className="block_div">
              <div>
                <img src={svgg} alt="img" />
              </div>
              <div className="tag_text">
                <p>Laravel CMS Development</p>
                <span>
                  Build eCommerce CMS websites with cutting-edge features and
                  aesthetics.
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-lg-3 col-md-12 col-12 block_main">
            <div className="block_div">
              <div>
                <img src={svgg} alt="img" />
              </div>
              <div className="tag_text">
                <p>Proven Track Records</p>
                <span>
                  Our track record is a symphony of success stories, showcasing
                  our commitment to excellence and the achievements of our
                  partners.
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-12 col-12 block_main">
            <div className="block_div">
              <div>
                <img src={svgg} alt="img" />
              </div>
              <div className="tag_text">
                <p>Cost-Effective Brilliance</p>
                <span>
                  We deliver cost-effective solutions that maximize the value of
                  your investment.
                </span>
              </div>
            </div>
          </div>
        </div> */}

        <div className="row">
          <div className="col-lg-3 col-md-6 col-12 block_main">
            <div className="block_div">
              <div>
                <img src={svgg} alt="img" />
              </div>
              <div className="tag_text">
                <p>API & Module Development</p>
                <span>
                  Delivering reliable and data-secure module development
                  solutions on Laravel Cloud Storage with custom API creation.
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 block_main">
            <div className="block_div">
              <div>
                <img src={svgg} alt="img" />
              </div>
              <div className="tag_text">
                <p>Laravel Business Development</p>
                <span>
                  Large-scale business-grade Laravel mobile app solutions that
                  help you achieve your business goals.
                </span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-12 col-12 block_main"></div>
          <div className="col-lg-3 col-md-12 col-12 block_main"></div>
        </div>

        <div class="cont_sub_btn mt-5 mb-5">
          <a href="#"> Ready to hire? </a>
        </div>

   
      </div>
    </Root>
  );
}
const Root = styled.section`
  padding: 0 50px;



        .cont_sub_btn {
    display: flex;
    justify-content: center;
 a {
    font-size:14px;
    color:white;
    background-color:#13c9df;
    padding:19px 31px;
    border:0;
    letter-spacing:3px;
    border-radius: 50px;
    transition: 0.2s;
    font-weight: 600;
    &:hover{
      background-color:#ececec;
      color:#13c9df;
    }
}
} 
  .text_div {
    margin-bottom: 0.7em;
    padding: 30px;
    text-align: center;
    .text {
      background: ${secGradient};
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: bold;
      font-size: 35px;
      line-height: 1.3em;
    }
    span {
      font-weight: bold;
      font-size: 35px;
      line-height: 1.3em;
    }
  }
  .block_main {
    margin-bottom: 20px;
    display: flex;
    flex: 1;
    .block_div {
      word-spacing: 1px;
      letter-spacing: 0.1px;
      padding: 10px;
      display: flex;
      border: 1px solid ${primaryColor};
      border-radius: 20px;
      flex-direction: column;
      align-items: center;
      &:hover {
        box-shadow: 1px 1px 5px 1px ${primaryColor};
        transition: 0.5s;
      }
      p {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
        padding-bottom: 10px;
      }
      span {
        font-size: 17px;
        color: #6e6e6e;
        padding-bottom: 20px;
      }
      .number_div {
        color: #147888;
        word-spacing: 1px;
        letter-spacing: 0.1px;
        font-size: 25px;
        font-weight: bold;
        line-height: 1.2em;
      }
      .tag_text {
        padding: 10px 0px;
        text-align: center;
      }
    }
  }

  @media (max-width: 567px) {
    padding: 0;

    .text_div {
      padding: 20px;
    }

    .block_main {
      display: unset;
      flex: unset;
    }
  }

  @media (min-width: 567px) and (max-width: 992px) {
    padding: 0;

    .block_main {
      display: unset;
      flex: unset;
    }

    .block_main .block_div {
      height: 300px;
    }
  }
`;
