import React from 'react'
import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'
import Section4 from './Section4'
import Section5 from './Section5'
import Formm from '../../../Home/Formm'
import { Helmet } from "react-helmet";
import Testimonial from '../HireShopify/Testimonial'
import Section6 from './Section6'
import Section7 from './Section7'
import Section8 from './Section8'
import Section9 from './Section9'
import Section10 from './Section10'
export default function HirePHP() {
  return (
    <div>
      <Helmet>
        <title>Offshore Php Development Company | Hire Php Developers </title>
        <meta
          name="description"
          content="As one of the Trusted offshore Php development Company, we deliver scalable and custom solutions for clients around the globe. Contact us today for a free quote!"
        />
      </Helmet>
        <Section1/>
        <Section2/>
        <Testimonial/>
        <Section3/>
        <Section6/>
        <Section4/>
        <Section7/>
        <Section8/>
        <Section9/>
        <Section10/>
        <Section5/>
        <Formm/>
    </div>
  )
}
