

import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import styled from "styled-components";
import { Link } from "react-router-dom";

function Test() {

  const [isListOpenService, setIsListOpenService] = useState(false);

  const handleMouseEnterService = () => {
    setIsListOpenService(true);
  };

  const handleMouseLeaveService = () => {
    setIsListOpenService(false);
  };

  const handleSubMenuClick = (event) => {
    event.stopPropagation(); // Prevent event propagation to parent elements
  };

  return (
    <Root>
      <div
        className="profile"
        onMouseEnter={handleMouseEnterService}
        onMouseLeave={handleMouseLeaveService}
        id="hireIndianTalent"
        onClick={handleSubMenuClick}
      >
        Services <IoIosArrowDown/>

        <div className={isListOpenService ? "sub_ser_div" : "off"}>
          
            <div className="container_fluid ">
              <div className="row">
                <div className="col-lg-3">
                  <div className="mega_div">
                    <span className="first-content"> DIGITAL MARKETING </span>
                    <p>
                      <Link to="/seo">SEO (Search Engine Optimisation)</Link>
                    </p>
                    <p>
                      <Link to="/socialmedia">Social Media Marketing</Link>
                    </p>
                    <p>
                      <Link to="/payperclick">Pay Per Click (PPC)</Link>
                    </p>
                    <p>
                      <Link to="/youtubemarketing">Youtube Marketing</Link>
                    </p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="mega_div">
                    <span className="first-content">DESIGN & DEVELOPMENT </span>
                    <p>
                      <Link to="/webdevelopment">Web Development</Link>
                    </p>
                    <p>
                      <Link to="/shopify">Shopify</Link>
                    </p>
                    <p>
                      <Link to="/java">Java</Link>
                    </p>
                    <p>
                      <Link to="/magento">Magento</Link>
                    </p>
                    <p>
                      <Link to="/wordpress">Wordpress</Link>
                    </p>
                    <p>
                      <Link to="/php">PHP</Link>
                    </p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="mega_div">
                    <span className="first-content">ANIMATIONS & GRAPHICS</span>
                    <p>
                      <Link to="/videomarketing">Video Marketing</Link>
                    </p>
                    <p>
                      <Link to="/branding">Branding</Link>
                    </p>
                    <p>
                      <Link to="/packagingandlabeling">
                        Packaging & Labeling
                      </Link>
                    </p>
                    <p>
                      <Link to="/graphicdesign">Graphic Design</Link>
                    </p>
                    <p>
                      <Link to="/websitedesign">Web Design</Link>
                    </p>
                    <p>
                      <Link to="/rotoscoping">Rotoscoping</Link>
                    </p>
                    <p>
                      <Link to="/animation">Animation</Link>
                    </p>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="mega_div">
                    <span className="first-content"> E-COMMERCE SOLUTION </span>
                    <p>
                      <Link to="/ecommercemarketing">E-Commerce Marketing</Link>
                    </p>
                    <p>
                      <Link to="/amazonmarketing">Amazon Marketing</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
   
        </div>
      </div>
    </Root>
  );
}

export default Test;

const Root = styled.section`
  position: relative;

  .profile {
    position: relative;
    cursor: pointer;
  }



  .sub_ser_div {
        position: absolute;
        top: 30px;
        left: -66px;
        border: 1px solid transparent;
        padding: 20px;
        z-index: 10;
        width: 860px;

        background-color: #ffffff;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        .row {
          width: 100%;
          margin-left: 10px;
          .mega_div {
            display: flex;
            gap: 10px;
            flex-direction: column;

            .first-content {
              font-size: 13px;
              font-weight: 500;
              line-height: 1.3em;
              color: #13c9df;
            }

            p {
              margin-bottom: 0;
              a {
                color: #000;
                font-size: 12px;
                text-decoration: none;
                &:hover {
                  color: #13c9df;
                }
              }
            }
          }
        }
      }

  .off {
    display: none;
  }
`;


