import React from "react";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Formm from "../../../Home/Formm";
import { Helmet } from "react-helmet";
import Testimonial from "../HireShopify/Testimonial";
import Section4 from "./Section4"
import Section5 from "./Section5";
import Section6 from "./Section6";
import Section7 from "./Section7";

export default function HireSoftware() {
  return (
    <div>
      <Helmet>
        <title>Software Development Company | Offshore Software Development 
        </title>
        <meta name="description" content="Offshore Software development company with expert developers. We believe in the power of customized solutions and always deliver projects on time." />
      </Helmet>
      <Section1/>
      <Section2/>
      <Testimonial/>
      <Section4/>
      <Section5/>
      <Section6/>
      <Section7/>
      <Section3/>
      <Formm/>
    </div>
  );
}
