import React from "react";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Formm from "../../../Home/Formm";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Testimonial from "./Testimonial";
import { Helmet } from "react-helmet";

export default function HireShopify() {
  return (
    <div>
      <Helmet>
        <title>
          Hire Shopify Development Company | Offshore Shopify Services
        </title>
        <meta
          name="description"
          content="With Phanom Professionals, you can hire dedicated Shopify developers. Our expert offshore Shopify developer studies your business idea for  dependable and expandable web design."
        />
      </Helmet>
      <Section1/>
      <Section2/>
      <Testimonial/>
      <Section4/>
      <Section5/>
      <Section6/>
      <Section3/>
      <Formm/>
    </div>
  );
}
