import React from "react";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Section7 from "./Section7";
import Section8 from "./Section8";
import Formm from "../../../Home/Formm";
import Testimonial from "../../HireDeveloper/HireShopify/Testimonial";
import { Helmet } from "react-helmet";

export default function HireSeoExpert() {
  return (
    <div>
      <Helmet>
        <title>Hire Offshore Seo Experts | Hire an SEO Specialist</title>
        <meta
          name="description"
          content=" Phanom Professionals, offshore Seo Experts  with 5 years of experience, delivers exceptional SEO services across globally with a proven track record."
        />
      </Helmet>
      <Section1 />
      <Section2 />
      <Testimonial />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <Formm />
    </div>
  );
}
