import React from "react";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Formm from "../../../Home/Formm";
import { Helmet } from "react-helmet";
import Testimonial from "../HireShopify/Testimonial"; 

export default function HireLaravel() {
  return (
    <div>
      <Helmet>
        <title>
          {" "}
          Hire Expert Laravel Developers | Offshore Laravel Developers
        </title>
        <meta
          name="description"
          content=" Need a Laravel Developer? Our Experts Deliver Secure, Efficient, and Scalable Web Applications. Hire offshore Laravel Developer to Bring Your Project to Life with Precision.
"
        />
      </Helmet>

      <Section1 />
      <Section2 />
      <Testimonial/>
      <Section3 />
      <Section4 />
      <Section5 />
      <Formm />
    </div>
  );
}
