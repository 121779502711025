import React from "react";
import styled from "styled-components";
import { primaryColor, secGradient } from "../../../GlobalStyles";

export default function Section11() {
  return (
    <Root>

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 col-md-12 col-12"></div>
          <div
            className="col-lg-8 col-md-12 col-12 text_div"
            data-aos="fade-up"
          >
            <span>What Makеs </span>
            <span className="text"> Us </span> <span> Uniquе?</span>
          </div>
          <p className="paragraph">
            At Phanom Professionals, we don’t just offer services; we innovate,
            create, and dominate. Our unique approach sets us apart in the
            competitive world of marketing and IT services. Here’s what makes us
            stand out:
          </p>
          <div className="col-lg-2 col-md-12 col-12"></div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 col-12 block_main1">
            <div className="block_div1">
              <div className="number_div1">01</div>
              <div>
                <h4 className="heading">Holistic Solutions:</h4>
                <span>
                  We seamlessly integrate marketing and IT, offering a
                  comprehensive suite of services that work together to enhance
                  your digital presence. 
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 block_main">
            <div className="block_div">
              <div className="number_div">02</div>
              <div>
                <h4 className="heading">Client-Centric Approach:</h4>
                <span>
                  Our focus is on you. We customize our solutions to align with
                  your brand’s unique vision and objectives, ensuring that every
                  strategy is tailored to meet your specific needs.
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 block_main">
            <div className="block_div">
              <div className="number_div">03</div>
              <div>
                <h4 className="heading">Innovative Thinking:</h4>
                <span>
                  Staying ahead of the curve is essential. We embrace the latest
                  technologies and trends, constantly innovating to provide
                  cutting-edge solutions that keep you competitive.
                </span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-12 block_main">
            <div className="block_div">
              <div className="number_div">04</div>
              <div>
                <h4 className="heading">Expert Team:</h4>
                <span>
                  Our team of seasoned professionals excels in both marketing
                  and IT. Whether you need creative branding strategies or
                  robust IT infrastructure, our experts bring unparalleled
                  expertise to the table.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
}
const Root = styled.section`
  padding: 0 50px;
  margin-top: 20px;

  .text_div {
    margin-bottom: 0.7em;
    padding: 20px 30px 0;
    text-align: center;
    .text {
      background: ${secGradient};
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;

      font-weight: bold;
      font-size: 35px;
      line-height: 1.3em;
    }
    span {
      font-weight: bold;
      font-size: 35px;
      line-height: 1.3em;
    }
  }

  .paragraph {
    font-size: 17px;
    color: #6e6e6e;
    text-align: center;
    margin-bottom: 20px;
  }

  .block_main {
    margin-bottom: 20px;
    display: flex;
    flex: 1;
    .block_div {
      word-spacing: 1px;
      letter-spacing: 0.1px;
      padding: 30px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      border: 1px solid #dadada;
      border-radius: 30px;
      &:hover {
        background-color: #dadada4f;
      }
      p {
        font-size: 20px;
        font-weight: bold;
        margin: 0;
        padding-bottom: 10px;
      }
      .heading {
        font-size: 20px;
        font-weight: 700;
        color: #212529;
      }
      span {
        font-size: 17px;
        color: #6e6e6e;
      }
      .number_div {
        color: ${primaryColor};
        word-spacing: 1px;
        letter-spacing: 0.1px;
        font-size: 25px;
        font-weight: bold;
        line-height: 1.2em;
      }
    }
  }

  .block_main1 {
    margin-bottom: 20px;
    display: flex;
    flex: 1;
    .block_div1 {
      background-color: ${primaryColor};
      word-spacing: 1px;
      letter-spacing: 0.1px;
      padding: 30px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      border: 1px solid #dadada;
      border-radius: 30px;

      p {
        font-size: 20px;
        font-weight: bold;
        margin: 0;
        padding-bottom: 10px;
        color: #fff;
      }
      .heading {
        font-size: 20px;
        font-weight: 700;
        color: #fff;
      }
      span {
        font-size: 17px;
        color: #fff;
      }
      .number_div1 {
        color: #fff;
        word-spacing: 1px;
        letter-spacing: 0.1px;
        font-size: 25px;
        font-weight: bold;
        line-height: 1.2em;
      }
    }
  }

  @media (max-width: 567px) {
    padding: 0;
    .block_main1 {
      flex: unset;
    }
    .block_main {
      flex: unset;
    }
  }
  @media (min-width: 567px) and (max-width: 992px) {
    padding: 0;
    .block_main1 {
      flex: unset;
    }
    .block_main {
      flex: unset;
    }
  }
`;
