import React from 'react'
import Section2 from './Section2'
import Section1 from './Section1'
import Section3 from './Section3'
import Formm from '../../../Home/Formm'
import { Helmet } from "react-helmet";
import Testimonial from '../HireShopify/Testimonial'
import Section4 from './Section4'
import Section5 from './Section5'

export default function HireIOS() {
  return (
    <div>
      <Helmet>
        <title>Offshore IOS App Development Company | Hire IOS Expert
        </title>
        <meta
          name="description"
          content="Looking to Hire an iOS Developer? Our Skilled Offshore Developers Offer Custom Ios App Development Tailored to Your Business Needs. Contact Us Today for Premium iOS App Solutions!"
        />
      </Helmet>
        <Section1/>
        <Section2/>
        <Testimonial/>
        <Section4/>
        <Section5/>
        <Section3/>
        <Formm/>
    </div>
  )
}
