import React from 'react'
import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'
import Section4 from './Section4'
import Section5 from './Section5'
import Section6 from './Section6'
import Formm from '../../../Home/Formm'
import { Helmet } from "react-helmet";
import Testimonial from '../HireShopify/Testimonial'
import Section7 from './Section7'
import Section8 from './Section8'

export default function HireReactNative() {
  return (
    <div>
      <Helmet>
        <title>Hire the Offshore  Expert React Native Developers </title>
        <meta
          name="description"
          content="We are a leading team of offshore React Native developers, ready to bring your app ideas to life. Hire us today for expert solutions and innovative results!"
        />
      </Helmet>
        <Section1/>
        <Section2/>
        <Testimonial/>
        <Section3/>
        <Section4/>
        <Section5/>
        <Section6/>
        <Section7/>
        <Section8/>
        <Formm/>
    </div>
  )
}
