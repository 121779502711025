import React from 'react'
import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'
import Formm from '../../../Home/Formm'
import { Helmet } from "react-helmet";
import Testimonial from '../HireShopify/Testimonial'
import Section4 from './Section4'
import Section5 from './Section5'

export default function HireAndroid() {
  return (
    <div>
      <Helmet>
        <title>Offshore Android App Development Company | Hire Android App Developer
        </title>
        <meta
          name="description"
          content="Android app development company for Custom Mobile App Development. Depending on your project requirements, Hire an Offshore Android developer. Contact Us today!"
        />
      </Helmet>
        <Section1/>
        <Section2/>
        <Testimonial/>
        <Section4/>
        <Section5/>
        <Section3/>
        <Formm/>
    </div>
  )
}
