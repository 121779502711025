import React from "react";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
// import Section5 from "./Section5";
import Section6 from "./Section6";
import Formm from "../../../Home/Formm";
import { Helmet } from "react-helmet";
import Testimonial from "../../HireDeveloper/HireShopify/Testimonial";
import Section7 from "./Section7";
import Section8 from "./Section8";
import Section9 from "./Section9";
import Section10 from "./Section10";

export default function HireWebDesigner() {
  return (
    <div>
      <Helmet>
        <title>
          {" "}
          Offshore Web Development Agency | Hire Expert Developers{" "}
        </title>
        <meta
          name="description"
          content="Ready to build something great? Phanom Professionals—offers expert web developers who turn ideas into stunning realities. offshore web designing services agency."
        />
      </Helmet>
      <Section1/>
      <Section2/>
      <Testimonial/>
      <Section3/>
      <Section7/>
      <Section8/>
      <Section9/>
      <Section10/>
      <Section4/>
      {/* <Section5/> */}
      <Section6/>
      <Formm/>
    </div>
  );
}
