import React from "react";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section5 from "./Section5";
import Section7 from "./Section7";
import Section8 from "./Section8";
import Section9 from "./Section9";
import Section10 from "./Section10";
import SubSlider from "./SubSlider";
import Formm from "./Formm";
import Section11 from "./Section11";
import { Helmet } from "react-helmet";

export default function Home() {
  return (
    <div>
      <Helmet>
        <title>
        Hire India's top talent | Biggest Indian talent network
        </title>
        <meta
          name="description"
          content="Discover top Indian talent with us. Hire the best software developers, designers, and more"
        /> 
      </Helmet>
      <Section1 />
      <SubSlider />
      <Section2 />
      <Section3 />
      <Section5 />
      <Section11 />
      <Section7 />
      <Section8 />
      <Section9 />
      <Section10 />
      <Formm />
      {/* <Footer/> */}
    </div>
  );
}
