import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "aos/dist/aos.css";
import { Route, Routes } from "react-router-dom";
import HirePA from "./Components/CommonPages/HireIndianTalent/HireDigitalMarketing/HirePA/HirePA";
import HireIOS from "./Components/CommonPages/HireIndianTalent/HireDeveloper/HireIOS/HireIOS";
import HireReactNative from "./Components/CommonPages/HireIndianTalent/HireDeveloper/HireReactNative/HireReactNative";
import WhyIndia from "./Components/CommonPages/WhyPhanom/WhyIndia/WhyIndia";
import HowItWorks from "./Components/CommonPages/WhyPhanom/HowItWorks/HowItWorks";
import HireWebDesigner from "./Components/CommonPages/HireIndianTalent/HireDesigner/HireWebDesigner/HireWebDesigner";
import HireUiuxDesigner from "./Components/CommonPages/HireIndianTalent/HireDesigner/HireUiuxDesigner/HireUiuxDesigner";
import HireGraphic from "./Components/CommonPages/HireIndianTalent/HireDesigner/HireGraphic/HireGraphic";
import HireReactJs from "./Components/CommonPages/HireIndianTalent/HireDeveloper/HireReactJs/HireReactJs";
import HireNodejs from "./Components/CommonPages/HireIndianTalent/HireDeveloper/HireNodeJs/HireNodejs";
import HireLaravel from "./Components/CommonPages/HireIndianTalent/HireDeveloper/HireLaravel/HireLaravel";
import HireShopify from "./Components/CommonPages/HireIndianTalent/HireDeveloper/HireShopify/HireShopify";
import HirePHP from "./Components/CommonPages/HireIndianTalent/HireDeveloper/HirePHP/HirePHP";
import HireWordPress from "./Components/CommonPages/HireIndianTalent/HireDeveloper/HireWordPress/HireWordPress";
import HireAndroid from "./Components/CommonPages/HireIndianTalent/HireDeveloper/HireAndroid/HireAndroid";
import HireSoftware from "./Components/CommonPages/HireIndianTalent/HireDeveloper/HireSoftware/HireSoftware";
import HireMobileApp from "./Components/CommonPages/HireIndianTalent/HireDeveloper/HireMobileApp/HireMobileApp";
import Home from "./Components/CommonPages/Home/Home";
import VettingProcess from "./Components/CommonPages/WhyPhanom/VettingProcess/VettingProcess";
import HirePpcExpert from "./Components/CommonPages/HireIndianTalent/HireDigitalMarketing/HirePpcExpert/HirePpcExpert";
import HireSeoExpert from "./Components/CommonPages/HireIndianTalent/HireDigitalMarketing/HireSeoExpert/HireSeoExpert";
import InnerPages from "./Components/Buyer/categoryPages/InnerPages";
import Layout from "./Components/MainLayouts/Layout";
import { useDispatch, useSelector } from "react-redux";
import Dashboard from "./Components/Seller/TopNavPages/Dashboard/Dashboard";
import Orders from "./Components/Seller/TopNavPages/MyBusiness/orders/Orders";
import Gigs from "./Components/Seller/TopNavPages/MyBusiness/gigs/Gigs";
import Profile from "./Components/Seller/TopNavPages/MyBusiness/profile/Profile";
import Earnings from "./Components/Seller/TopNavPages/MyBusiness/earning/Earnings";
import Overview from "./Components/Seller/TopNavPages/Analytics/Overview";
import CreatePage from "./Components/Seller/create/CreatePage";
import PageNF from "./Components/Important/PageNF";
import { loaderAction } from "./redux/users/action";
import { useEffect } from "react";
import Loader from "./Components/Important/Loader";
import Analytics from "./Components/Seller/TopNavPages/Analytics/Analytics";
import LoginSeller from "./Components/CommonPages/loginPages/LoginSeller";
import Pricing from "./Components/Seller/create/pricing/Pricing";
import DescriptionFAQ from "./Components/Seller/create/description/DescriptionFAQ";
import Gallery from "./Components/Seller/create/gallery/Gallery";
import Requirements from "./Components/Seller/create/requirements/Requirements";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditGigsPage from "./Components/Seller/TopNavPages/MyBusiness/gigs/editGigs/EditGigsPage";
import Topkeywords from "./Components/Seller/TopNavPages/Analytics/Topkeywords";
import LoginBuyer from "./Components/CommonPages/loginPages/LoginBuyer";
import Page1 from "./Components/Buyer/profilePlanPages/Page1";
// import Userone from "./Components/Chat/Userone";
import CreateSingMile from "./Components/Chat/CreateSingMile";
import SellerRegister from "./Components/CommonPages/loginPages/SellerRegister";
import Portfolio from "./Components/Portfolio/Portfolio";
import CreatePortfolio from "./Components/Portfolio/CreatePortfolio";
import NoInternetConnection from "./Components/Important/NoInternetConnection";
import BuyerRegister from "./Components/CommonPages/loginPages/BuyerRegister";
import BrowseHistory from "./Components/Buyer/categoryPages/BrowseHistory";
import Level from "./Components/Seller/TopNavPages/Dashboard/Level";
import ShowOrder from "./Components/Seller/TopNavPages/MyBusiness/orders/showOrders/ShowOrder";
import Preview from "./Components/Seller/TopNavPages/MyBusiness/profile/Preview/Preview";
import Seo from "./Components/Serv/SEO/Seo";
import YoutubeMarketing from "./Components/Serv/YoutubeMarketing/YoutubeMarketing";
import PayPerClick from "./Components/Serv/PayPerClick/PayPerClick";
import WebDevelopment from "./Components/Serv/WebDevelopment/Webdevelopment";
import Java from "./Components/Serv/Java/Java";
import Shopify from "./Components/Serv/Shopify/Shopify";
import Magento from "./Components/Serv/Magento/Magento";
import GraphicDesign from "./Components/Serv/GraphicDesign/GraphicDesign";
import WebsiteDesign from "./Components/Serv/WebsiteDesign/WebsiteDesign";
import RotoScoping from "./Components/Serv/RotoScoping/RotoScoping";
import Animation from "./Components/Serv/Animation/Animation";
import ECommerceMarketing from "./Components/Serv/ECommerceMarketing/ECommerceMarketing";
import AmazonMarketing from "./Components/Serv/AmazonMarketing/AmazonMarketing";
import Wordpress from "./Components/Serv/Wordpress/Wordpress";
import Php from "./Components/Serv/Php/Php";
import VideoMarketing from "./Components/Serv/VideoMarketing/VideoMarketing";
import Branding from "./Components/Serv/Branding/Branding";
import PackagingandLabeling from "./Components/Serv/PackagingandLabeling/PackagingandLabeling";
import Socialmedia from "./Components/Serv/Socialmedia/Socialmedia";
import Ourportfolio from "./Components/CommonPages/Ourportfolio/Ourportfolio";
import Test from "./Test";
import ShopifyPublicApp from "./Components/CommonPages/HireIndianTalent/HireDeveloper/ShopifyPublicApp/ShopifyPublicApp";

function App() {
  const userCheck = useSelector((state) => state?.users?.userCheck);
  const userDetails = useSelector((state) => state?.users.user);
  const token = localStorage.getItem("token");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loaderAction(false));
  });

  return (
    <div>
      <NoInternetConnection>
        <Layout>
          <Routes>
            {userCheck && token ? (
              <>
                {/* <Route path="/user" element={<Userone />} /> */}
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/level" element={<Level />} />
                <Route path="/" element={<InnerPages />} />
                <Route path="/profile/:username" element={<Profile />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/preview" element={<Preview />} />
                <Route path="/preview/:id" element={<Preview />} />

                {userDetails.role === "buyer" ? (
                  <>
                    {/* buyer pagess--------------------------------------------------------------- */}
                    <Route path="/editgigspages/:id" element={<Page1 />} />
                    <Route path="/browser/:id" element={<BrowseHistory />} />
                    <Route path="/browser" element={<BrowseHistory />} />
                    <Route path="/innerpages" element={<InnerPages />} />
                  </>
                ) : userDetails.role === "seller" ? (
                  <>
                    {/* seller pages ---------------------------------------------------------------*/}
                    <Route
                      path="/createportfolio"
                      element={<CreatePortfolio />}
                    />
                    <Route
                      path="/createsingmile"
                      element={<CreateSingMile />}
                    />
                    <Route path="/preview/:id" element={<Preview />} />
                    <Route path="/preview" element={<Preview />} />
                    <Route path="/showorder" element={<ShowOrder />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/editgigspages/:id" element={<Page1 />} />
                    <Route path="/orders" element={<Orders />} />
                    <Route path="/gigs" element={<Gigs />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/description" element={<DescriptionFAQ />} />
                    <Route path="/requirements" element={<Requirements />} />
                    <Route path="/gallery" element={<Gallery />} />
                    <Route path="/create" element={<CreatePage />} />
                    <Route path="editgigs/:gigId" element={<EditGigsPage />} />
                    <Route path="editgigs" element={<EditGigsPage />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/earnings" element={<Earnings />} />
                    <Route path="/analytics" element={<Analytics />} />
                    <Route path="/overview" element={<Overview />} />
                    <Route path="/topkeywords" element={<Topkeywords />} />
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                {/* common pages -------------------------------------------------------------- */}
                <Route path="/" element={<Home />} />
                <Route path="/editgigspages/:id" element={<Page1 />} />
                <Route path="/editgigspages" element={<Page1 />} />
                <Route path="/home" element={<Home />} />
                <Route path="*" element={<PageNF />} />
                <Route path="/vettingprocess" element={<VettingProcess />} />
                <Route path="/hiremobile" element={<HireMobileApp />} />
                <Route path="/hiresoftware" element={<HireSoftware />} />
                <Route path="/hireandroid" element={<HireAndroid />} />
                <Route path="/hirewordpress" element={<HireWordPress />} />
                <Route path="/hirephp" element={<HirePHP />} />
                <Route path="/hireshopify" element={<HireShopify />} />
                <Route path="/hirelaravel" element={<HireLaravel />} />
                <Route path="/hirenode" element={<HireNodejs />} />
                <Route path="/hirereact" element={<HireReactJs />} />
                <Route path="/hiregraphic" element={<HireGraphic />} />
                <Route path="/hireuiux" element={<HireUiuxDesigner />} />
                <Route path="/hireweb" element={<HireWebDesigner />} />
                <Route path="/hirepa" element={<HirePA />} />
                <Route path="/hireseo" element={<HireSeoExpert />} />
                <Route path="/hireppc" element={<HirePpcExpert />} />
                <Route path="/hireios" element={<HireIOS />} />
                <Route path="/hirereactnative" element={<HireReactNative />} />
                <Route path="/whyindia" element={<WhyIndia />} />
                <Route path="/howitworks" element={<HowItWorks />} />
                <Route path="/loginBuyer" element={<LoginBuyer />} />
                <Route path="/loginseller" element={<LoginSeller />} />
                <Route path="/sellerregister" element={<SellerRegister />}/>

                <Route path="/buyerregister" element={<BuyerRegister/>} />

                <Route path="/seo" element={<Seo />}/>
                <Route path="/ourportfolio" element={<Ourportfolio/>}/>

                {/* <Route
                  path="socialmediamarketing"
                  element={<SocialMediaMarketing />}
                /> */}

                <Route path="/youtubemarketing" element={<YoutubeMarketing />} />
                <Route path="/payperclick" element={<PayPerClick />} />
                <Route path="/webdevelopment" element={<WebDevelopment />} />
                <Route path="/java" element={<Java />}/>
                <Route path="/shopify" element={<Shopify />}/>
                <Route path="/magento" element={<Magento />}/>
                <Route path="/graphicdesign" element={<GraphicDesign />}/>
                <Route path="/websitedesign" element={<WebsiteDesign />}/>
                <Route path="/rotoScoping" element={<RotoScoping />}/>
                <Route path="/animation" element={<Animation />}/>
                <Route
                  path="/ecommercemarketing"
                  element={<ECommerceMarketing />}
                />
                <Route path="/amazonmarketing" element={<AmazonMarketing />} />
                <Route path="/wordpress" element={<Wordpress />} />
                <Route path="/php" element={<Php />} />
                <Route path="/videomarketing" element={<VideoMarketing/>} />
                <Route path="/branding" element={<Branding />}/>
                <Route
                  path="/packagingandlabeling"
                  element={<PackagingandLabeling/>}
                />
                <Route path="/socialmedia" element={<Socialmedia/>}/>
                <Route path="/test" element={<Test/>}/>
                <Route path="/shopify-public-app" element={<ShopifyPublicApp/>}/>

              </>
            )}
          </Routes>
          <Loader />
          <ToastContainer />
        </Layout>
      </NoInternetConnection>
    </div>
  );
}

export default App;
