import React from "react";
import Section5 from "./Section5";
import Section4 from "./Section4";
// import Section3 from "./Section3";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Formm from "../../../Home/Formm";
import { Helmet } from "react-helmet";
import Testimonial from "../../HireDeveloper/HireShopify/Testimonial";
import Section6 from "./Section6";

export default function HireGraphic() {
  return (
    <div>
      <Helmet>
        <title>Hire Offshore Graphic Designers | Hire Expert Design</title>
        <meta
          name="description"
          content="Need the professional graphic designer?  Hire offshore Graphic Designers Connect with us and get inspired by top-notch talent ready to bring your vision to life."
        />

      </Helmet>
      <Section1 />
      <Section2 />
      <Testimonial/>
      {/* <Section3 /> */}
      <Section4 />
      <Section6/>   
      <Section5 />
      <Formm />
    </div>
  );
}
