import React from "react";
import styled from "styled-components";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import one from "../../Images/one.png";
import two from "../../Images/two.png";
import three from "../../Images/three.png";
import { primaryColor, secGradient } from "../../../GlobalStyles";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Optimization (SEO)",
  "Social Media",
  "Application Development",
  "Shopify API Development",
  "Web Design",
  "ECommerce Optimization",
  "Website Development",
  "WordPress Plugin",
  "Pay Per Click (PPC)",
  "Shopify Development",
  "Custom Website Development",
  "Ecommerce Website Development",
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export default function Formm() {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const [age, setAge] = React.useState("");

  const handleChang = (event) => {
    setAge(event.target.value);
  };
  return (
    <Root>
      <div className="container-fluid">
        <div className="row bg-color">
          <div className="col-lg-7   col-md-12 col-12 first-col">
            <div className="form-div">
              <span className="black-content">Experience </span>
              <span className="colored-content">Real </span>
              <span className="black-content">Results</span>
              <p className="paragraph">
                Partner with Phanom Internet Marketing Agency and scale your
                business.
              </p>

              <div className="row field_div">
                <div className="col-lg-6 col-md-12 col-12">
                  <input type="text" placeholder="Name*"></input>
                </div>
                <div className="col-lg-6 col-md-12 col-12 sec-input">
                  <input type="number" placeholder="Phone*"></input>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-6 col-md-12 col-12">
                  <input type="email" placeholder="Email Address*"></input>
                </div>
                {/* <div className="col-lg-6 col-md-12 col-12 sec-input">
                  <input type="email" placeholder="Email Address*"></input>
                </div> */}
              </div>
              {/* <div className="row mt-3">
                <div className="col-lg-6 col-md-12 col-12">
                  <input
                    type="email"
                    placeholder="Company/Organization*"
                  ></input>
                </div>
                <div className="col-lg-6 col-md-12 col-12 sec-input">
                  <input type="text" placeholder="Website Link* "></input>
                </div>
              </div> */}

              <div className="row mt-3">
                <div className="col-lg-12 col-12">
                  <label className="label_heading">
                    What services can we provide you?
                  </label>
                  <div className="row space_div">
                    <FormControl className="serv_div">
                      <Select
                        multiple
                        displayEmpty
                        value={personName}
                        onChange={handleChange}
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <em>Services</em>;
                          }

                          return selected.join(", ");
                        }}
                        MenuProps={MenuProps}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem disabled value="">
                          <em></em>
                        </MenuItem>
                        {names.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, personName, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-lg-12  col-12">
                  <label className="label_heading">What is your Budget?</label>
                  <br></br>

                  <FormControl className="choos_div">
                    <Select
                      value={age}
                      onChange={handleChang}
                      displayEmpty
                      inputProps={{ "aria-label": "" }}
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value={10}>$500-$1000</MenuItem>
                      <MenuItem value={20}>$1000-$2000</MenuItem>
                      <MenuItem value={30}>$2000-$3000</MenuItem>
                      <MenuItem value={30}>$3000-$5000</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-12  col-12">
                  <label className="label_heading">
                    How soon you want to start this project?
                  </label>

                  <div className="start_div">
                    <div className="sub_start_div">
                      <input type="radio" name="project_timing"></input>

                      <label>Immediate</label>
                    </div>

                    <div className="sub_start_div">
                      <input type="radio" name="project_timing"></input>

                      <label>With in a Week</label>
                    </div>
                  </div>
                  <div className="start_div">
                    <div className="sub_start_div">
                      <input type="radio" name="project_timing"></input>

                      <label>With in 15 days</label>
                    </div>

                    <div className="sub_start_div">
                      <input type="radio" name="project_timing"></input>

                      <label>Just Searching Options</label>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-lg-6 col-md-6  col-6  rad_div">
                      <input type="radio" name="project_timing"></input>

                      <label>Immediate</label>
                      <br></br>
                      <input type="radio" name="project_timing"></input>
                      <label>With in 15 days</label>
                      <br></br>
                    </div>

                    <div className="col-lg-6 col-md-6 col-6">
                      <input type="radio" name="project_timing"></input>
                      <label>With in a Week</label>
                      <br></br>
                      <input type="radio" name="project_timing"></input>
                      <label>Just Searching Options</label>
                      <br></br>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-12">
                  <label className="label_heading">Share Project Details</label>
                  <br />
                  <textarea name="message" rows="5" cols="65"></textarea>
                </div>
              </div>

            
                  <div className="sub_btn">
                    <button>Sumbit</button>
                  </div>
               
             
            </div>
          </div>

          <div className="col-lg-5  col-md-12 col-12 second-col">
            <div className="row ">
              <div className="col-lg-2 col-md-2  col-2">
                <FaRegArrowAltCircleLeft />
              </div>

              <div className="col-lg-10 col-md-10 col-10 gap_cont">
                <h2 className="heading ready_cont">
                  Ready to Dominate Your Market?
                </h2>
                <p className="paragraph">
                  Partner with Phanom Internet Marketing Agency and scale your
                  business.
                </p>
              </div>
            </div>

            <div className="d-flex  img_cont">
              <img src={one} alt="img" className="img-fluid" />
              <div>
                <h2 className="heading">Contact Us</h2>
                <p className="paragraph">
                  Fill out our brief contact form, and we’ll be in touch with
                  you soon to learn more about your business.
                </p>
              </div>
            </div>

            <div className="d-flex  img_cont">
              <img src={two} alt="img" className="img-fluid" />
              <div>
                <h2 className="heading">Launch Targeted Campaigns</h2>
                <p className="paragraph">
                  Our digital marketing experts will work closely with you to
                  implement data-driven strategies that drive business growth
                </p>
              </div>
            </div>

            <div className="d-flex  img_cont">
              <img src={three} alt="img" className="img-fluid" />
              <div>
                <h2 className="heading">Achieve Lasting Growth</h2>
                <p className="paragraph">
                  Solidify your digital presence, expand your brand reach and
                  see outstanding results month-over-month.{" "}
                </p>
              </div>
            </div>

            <div className="row mt-3 border-top">
              <div className="col-lg-6 col-6">
                <h5>Headquarters:</h5>
                <p className="paragraph">Chandigarh</p>
                <h5>Branches:</h5>
                <p className="paragraph">Punjab & United Kingdom</p>
              </div>

              <div className="col-lg-6 col-6">
                <h5>Office Hours:</h5>
                <p className="paragraph">Mon-Fri, 10:00am – 7:00pm</p>
                <h5>Main Phone:</h5>
                <a href="tel:9915452169">(628) 007-2655</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
}
const Root = styled.section`
  background-color: #ffffffd4;

  .bg-color {
    background-color: ${primaryColor};
    margin-top: 20px;
  }
  .first-col {
    padding: 50px 0 50px 30px;
  }

  .form-div {
    background-color: #f3f3f3;
    border-radius: 30px;
    padding: 20px 20px 30px 30px;
    .colored-content {
      font-size: 35px;
      font-weight: bold;
      line-height: 1.3em;
      background: ${secGradient};
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .black-content {
      font-size: 35px;
      font-weight: bold;
      line-height: 1.3em;
    }
    .paragraph {
      font-size: 13px;
      color: #6e6e6e;
      padding-top: 5px;
    }

    .field_div {
      gap: 10px;
    }
    input[type="text"] {
      border: 1px solid #dadada;
      height: 50px;
      width: 100%;
      background-color: #f3f3f3;
      padding-left: 10px;
      border-radius: 5px;
      &:hover {
        border: 1px solid #000000;
      }
    }

    input[type="number"] {
      border: 1px solid #dadada;
      height: 50px;
      width: 100%;
      background-color: #f3f3f3;
      padding-left: 10px;
      border-radius: 5px;
      &:hover {
        border: 1px solid #000000;
      }
    }

    input[type="email"] {
      border: 1px solid #dadada;
      height: 50px;
      width: 100%;
      background-color: #f3f3f3;
      padding-left: 10px;
      border-radius: 5px;
      &:hover {
        border: 1px solid #000000;
      }
    }

    .sec-input {
      margin-left: -22px;
    }

    ::placeholder {
      font-size: 14px;
      color: #6e6e6e;
      font-weight: 400;
    }

    label {
      font-size: 11px;
      color: #6e6e6e;
      /* /* padding-top: 5px; */
      /* padding-left: 2px;  */
    }

    .start_div {
      display: flex;
      padding-top: 10px;
      .sub_start_div {
        flex: 1;
        display: flex;
        gap: 6px;
        align-items: center;
        input {
          cursor: pointer;
        }
        label {
          cursor: pointer;
        }
      }
    }
    /* .rad_div{
      input{
        margin-top:10px;
      }
      label {
      font-size: 11px;
      color: #6e6e6e;
      margin:0 0 0 10px;
   
    }

    } */
    select {
      border: 1px solid #dadada;
      height: 50px;
      width: 90%;
      background-color: #f3f3f3;
      padding-left: 10px;
      border-radius: 5px;
      &:hover {
        border: 1px solid #000000;
      }
    }

    option {
      font-size: 14px;
      color: #6e6e6e;
      font-weight: 400;
    }

    textarea {
      background-color: #f3f3f3;
      border-radius: 10px;
      /* margin-top:5px; */
      padding: 10px 0 0 10px;
      border: 1px solid #dadada;
    }

    .sub_btn{
      display: flex;
      justify-content: center;
    button {
      font-size: 14px;
      color: white;
      background-color: ${primaryColor};
      text-transform: uppercase;
      padding: 13px 81px;
      border: 0;
      letter-spacing: 3px;
      border-radius: 50px;
      margin: 15px 0 0 0;
    }
  }
    .label_heading {
      font-size: 15px;
      color: #212529;
      font-weight: 600;
      margin-bottom: 7px;
    }
  }

  .second-col {
    padding: 50px 0 50px 30px;

    .heading {
      font-size: 28px;
      font-weight: bold;
      line-height: 1.3em;
      color: #fff;
    }

    .ready_cont {
      padding-top: 13px;
    }

    svg {
      font-size: 40px;
      color: #fff;
      margin: 16px 0 0 22px;
    }

    .paragraph {
      font-size: 13px;
      color: #ffffff;
      padding-right: 20px;
    }

    .border-top {
      border-top: 1px solid white;
      margin-left: 13px;
      width: 92%;
      padding-top: 30px;

      h5 {
        font-size: 15px;
        color: #fff;
        font-weight: 600;
        margin-bottom: 7px;
      }

      a {
        font-size: 13px;
        color: #ffffff;
        padding-right: 20px;
        text-decoration: none;
      }
    }
  }

  .gap_cont {
    margin-left: -17px;
  }

  .img_cont {
    padding-left: 14px;
    gap: 22px;
    img {
      height: 45px;
      width: 45px;
      object-fit: cover;
    }
  }

  .css-vamk89-MuiFormControl-root {
    width: 88%;
    margin-top: 0;
  }

  .space_div {
    padding-left: 10px;
  }

  .choos_div {
    width: 98%;
  }

  .serv_div {
    width: 97%;
  }

  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    border: 1px solid #fffbfb !important;
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #000000 !important;
  }

  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #000000;
  }
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    border: 1px solid #ffffff;
    padding: 13.5px 14px;
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    &:hover {
      border-color: #ffffff;
    }
  }

  .css-1869usk-MuiFormControl-root {
    width: 91%;
    margin: 0;
  }

  @media (max-width: 567px) {
    .first-col {
      padding: 20px 20px 0 20px;
    }
    .form-div {
      padding: 20px 0 30px 15px;
      .sec-input {
        margin: 10px 0 0 0;
      }
      textarea {
        width: 95%;
      }
      button {
        margin: 15px 0 0 25px;
      }
      label {
        font-size: 10px;
      }
    }

    .second-col {
      padding: 50px 0 50px 13px;
      svg {
        margin: 16px 0 0 2px;
        transform: rotate(90deg);
      }
      .heading {
        font-size: 18px;
      }

      .paragraph {
        font-size: 9px;
        color: #ffffff;
        padding-right: 20px;
      }
    }

    /* .row {
      margin-right: 0;
      margin-left:0;
    }  */
    :nth-child(1) {
      margin-right: 0px;
      margin-left: 0px;
    }

    .css-vamk89-MuiFormControl-root {
      width: 85%;
      margin-left: 8px !important;
    }

    .form-div input[type="text"] {
      width: 97%;
    }

    .form-div input[type="number"] {
      width: 97%;
    }

    .form-div input[type="email"] {
      width: 97%;
    }

    .serv_div {
      width: 94%;
    }

    .choos_div {
      width: 97%;
    }

    .gap_cont {
      margin-left: 0;
    }

    .img_cont {
      gap: 10px;
    }
  }

  @media (min-width: 567px) and (max-width: 992px) {
    .css-vamk89-MuiFormControl-root {
      width: 88%;
      margin-left: 8px !important;
    }
    .first-col {
      padding: 20px 20px 0 20px;
      overflow: hidden;
    }
    .form-div {
      padding: 20px 0 30px 30px;
      .sec-input {
        margin: 10px 0 0 0;
      }
      textarea {
        width: 95%;
      }
      button {
        margin: 15px 0 0 10px;
      }
    }

    .second-col {
      /* padding: 50px 0 50px 13px; */
      svg {
        /* margin:16px 0 0 2px; */
        transform: rotate(90deg);
      }
      .paragraph {
        font-size: 17px;
      }

      .heading {
        padding-top: 13px;
      }
    }
    :nth-child(1) {
      margin-right: 0px;
      margin-left: 0px;
    }

    .img_cont {
      align-items: center;
    }

    .form-div input[type="text"] {
      width: 97%;
    }

    .form-div input[type="number"] {
      width: 97%;
    }

    .form-div input[type="email"] {
      width: 97%;
    }

    .serv_div {
      width: 95%;
    }

    .choos_div {
      width: 97%;
    }

    .gap_cont {
      margin-left: 0;
    }

    .img_cont {
      gap: 70px;
      padding-left: 31px;
    }
  }
`;
