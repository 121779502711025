import styled from "styled-components";
import { useState } from "react";
import { Link } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import newlog from "../Images/newlog.png";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import ContainerFluid from "react-bootstrap/Container";
import { primaryColor, secGradient } from "../../GlobalStyles";
import Modal from "react-bootstrap/Modal";
// import { IoIosArrowForward } from "react-icons/io";
// import { IoIosArrowBack } from "react-icons/io";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React from "react";
import TimezoneSelect from "react-timezone-select";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const names = [
  "Optimization (SEO)",
  "Social Media",
  "Application Development",
  "Shopify API Development",
  "Web Design",
  "ECommerce Optimization",
  "Website Development",
  "WordPress Plugin",
  "Pay Per Click (PPC)",
  "Shopify Development",
  "Custom Website Development",
  "Ecommerce Website Development",
];
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export default function Navbarr() {
  const theme = useTheme();
  const [isListOpen2, setIsListOpen2] = useState(false);
  const [isListOpen3, setIsListOpen3] = useState(false);
  const [isListOpen4, setIsListOpen4] = useState(false);
  const [isListOpen5, setIsListOpen5] = useState(false);
  const [personName, setPersonName] = React.useState([]);
  const [isListOpenService, setIsListOpenService] = useState(false);

  const [show, setShow] = useState(false);
  // const [sDate, setsDate] = useState(new Date());

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [startDate, setStartDate] = useState(new Date());

  const handleMouseEnter2 = () => {
    setIsListOpen2(true);
  };

  const handleMouseLeave2 = () => {
    setIsListOpen2(false);
  };

  const handleMouseEnter3 = () => {
    setIsListOpen3(true);
  };

  const handleMouseLeave3 = () => {
    setIsListOpen3(false);
  };

  const handleMouseEnter4 = () => {
    setIsListOpen4(true);
  };

  const handleMouseLeave4 = () => {
    setIsListOpen4(false);
  };

  const handleMouseEnter5 = () => {
    setIsListOpen5(true);
  };

  const handleMouseLeave5 = () => {
    setIsListOpen5(false);
  };

  const handleMouseEnterService = () => {
    setIsListOpenService(true);
  };

  const handleMouseLeaveService = () => {
    setIsListOpenService(false);
  };

  const handleSubMenuClick = (event) => {
    event.stopPropagation(); // Prevent event propagation to parent elements
  };

  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <Root>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="bg-body-tertiary nav-menu"
      >
        <ContainerFluid fluid style={{ flexWrap: "wrap !important" }}>
          <Navbar.Brand href="/">
            {" "}
            <img src={newlog} alt="img" />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>

          <Navbar.Collapse
            id="basic-navbar-nav"
            style={{ justifyContent: "space-around" }}
          >
            <Nav className="main_nav_bar">
              <Nav>
                {" "}
                {/* <div className="profile  serv_div">
                  <Link to="/home">
                    Services
                    <IoIosArrowDown />
                  </Link>

                  <div className="container_fluid sub_ser_div">
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="mega_div">
                          <span className="first-content">
                            {" "}
                            DIGITAL MARKETING{" "}
                          </span>
                          <p>
                            <Link to="/seo">
                              SEO (Search Engine Optimisation)
                            </Link>
                          </p>
                          <p>
                            <Link to="/socialmedia">
                              Social Media Marketing
                            </Link>
                          </p>
                          <p>
                            <Link to="/payperclick">Pay Per Click (PPC)</Link>
                          </p>
                          <p>
                            <Link to="/youtubemarketing">
                              Youtube Marketing
                            </Link>
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="mega_div">
                          <span className="first-content">
                            DESIGN & DEVELOPMENT{" "}
                          </span>
                          <p>
                            <Link to="/webdevelopment">Web Development</Link>
                          </p>
                          <p>
                            <Link to="/shopify">Shopify</Link>
                          </p>
                          <p>
                            <Link to="/java">Java</Link>
                          </p>
                          <p>
                            <Link to="/magento">Magento</Link>
                          </p>
                          <p>
                            <Link to="/wordpress">Wordpress</Link>
                          </p>
                          <p>
                            <Link to="/php">PHP</Link>
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="mega_div">
                          <span className="first-content">
                            ANIMATIONS & GRAPHICS
                          </span>
                          <p>
                            <Link to="/videomarketing">Video Marketing</Link>
                          </p>
                          <p>
                            <Link to="/branding">Branding</Link>
                          </p>
                          <p>
                            <Link to="/packagingandlabeling">
                              Packaging & Labeling
                            </Link>
                          </p>
                          <p>
                            <Link to="/graphicdesign">Graphic Design</Link>
                          </p>
                          <p>
                            <Link to="/websitedesign">Web Design</Link>
                          </p>
                          <p>
                            <Link to="/rotoscoping">Rotoscoping</Link>
                          </p>
                          <p>
                            <Link to="/animation">Animation</Link>
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="mega_div">
                          <span className="first-content">
                            {" "}
                            E-COMMERCE SOLUTION{" "}
                          </span>
                          <p>
                            <Link to="/ecommercemarketing">
                              E-Commerce Marketing
                            </Link>
                          </p>
                          <p>
                            <Link to="/amazonmarketing">Amazon Marketing</Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div
                  className="profile"
                  onMouseEnter={handleMouseEnterService}
                  onMouseLeave={handleMouseLeaveService}
                  id="hireIndianTalent"
                  onClick={handleSubMenuClick}
                >
                  Services <IoIosArrowDown />
                  <div className={isListOpenService ? "sub_ser_div" : "off"}>
                    <div className="serv_megamenu_div">
                      <div className="mega_div">
                        <span className="first-content">
                          {" "}
                          DIGITAL MARKETING{" "}
                        </span>
                        <p>
                          <Link to="/seo">
                            SEO (Search Engine Optimisation)
                          </Link>
                        </p>
                        <p>
                          <Link to="/socialmedia">Social Media Marketing</Link>
                        </p>
                        <p>
                          <Link to="/payperclick">Pay Per Click (PPC)</Link>
                        </p>
                        <p>
                          <Link to="/youtubemarketing">Youtube Marketing</Link>
                        </p>
                      </div>

                      <div className="mega_div">
                        <span className="first-content">
                          DESIGN & DEVELOPMENT{" "}
                        </span>
                        <p>
                          <Link to="/webdevelopment">Web Development</Link>
                        </p>
                        <p>
                          <Link to="/shopify">Shopify</Link>
                        </p>
                        <p>
                          <Link to="/java">Java</Link>
                        </p>
                        <p>
                          <Link to="/magento">Magento</Link>
                        </p>
                        <p>
                          <Link to="/wordpress">Wordpress</Link>
                        </p>
                        <p>
                          <Link to="/php">PHP</Link>
                        </p>
                      </div>

                      <div className="mega_div">
                        <span className="first-content">
                          ANIMATIONS & GRAPHICS
                        </span>
                        <p>
                          <Link to="/videomarketing">Video Marketing</Link>
                        </p>
                        <p>
                          <Link to="/branding">Branding</Link>
                        </p>
                        <p>
                          <Link to="/packagingandlabeling">
                            Packaging & Labeling
                          </Link>
                        </p>
                        <p>
                          <Link to="/graphicdesign">Graphic Design</Link>
                        </p>
                        <p>
                          <Link to="/websitedesign">Web Design</Link>
                        </p>
                        <p>
                          <Link to="/rotoscoping">Rotoscoping</Link>
                        </p>
                        <p>
                          <Link to="/animation">Animation</Link>
                        </p>
                      </div>

                      <div className="mega_div">
                        <span className="first-content">
                          {" "}
                          E-COMMERCE SOLUTION{" "}
                        </span>
                        <p>
                          <Link to="/ecommercemarketing">
                            E-Commerce Marketing
                          </Link>
                        </p>
                        <p>
                          <Link to="/amazonmarketing">Amazon Marketing</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Nav>

              <Nav>
                <div
                  className="profile"
                  onMouseEnter={handleMouseEnter2}
                  onMouseLeave={handleMouseLeave2}
                  id="hireIndianTalent"
                  onClick={handleSubMenuClick}
                >
                  Hire Indian Talent <IoIosArrowDown />
                  <div className={isListOpen2 ? "option_list_list" : "off"}>
                    <div
                      className="mini_option_list"
                      id="hireDM"
                      onMouseEnter={handleMouseEnter3}
                      onMouseLeave={handleMouseLeave3}
                      onClick={() => {
                        setIsListOpen3(!isListOpen3);
                      }}
                    >
                      Hire Digital Markitng <IoIosArrowDown />
                      <div className={isListOpen3 ? "option_list2" : "off"}>
                        <p>
                          <Link to="/hireppc">Hire PPC Expert</Link>
                        </p>
                        <p>
                          <Link to="/hireseo">Hire SEO Expert </Link>
                        </p>
                        {/* <p>
                          <Link to="/hirepa">
                            Hire Programmatic Advertisement
                          </Link>
                        </p> */}
                      </div>
                    </div>
                    <div
                      id="hireD"
                      className="mini_option_list"
                      onMouseEnter={handleMouseEnter4}
                      onMouseLeave={handleMouseLeave4}
                    >
                      Hire Designer <IoIosArrowDown />
                      <div className={isListOpen4 ? "option_list3" : "off"}>
                        <p>
                          <Link to="/hiregraphic">Hire Graphic Designer</Link>
                        </p>
                        <p>
                          <Link to="/hireuiux">Hire UI/UX Designer </Link>
                        </p>
                        <p>
                          <Link to="/hireweb">Hire Web Designer</Link>
                        </p>
                      </div>
                    </div>
                    <div
                      id="hireDeveloper"
                      className="mini_option_list"
                      onMouseEnter={handleMouseEnter5}
                      onMouseLeave={handleMouseLeave5}
                    >
                      Hire Developer <IoIosArrowDown />
                      <div className={isListOpen5 ? "option_list4" : "off"}>
                        <p>
                          <Link to="/hirereact">Hire ReactJs Developer</Link>
                        </p>
                        <p>
                          <Link to="/hirenode">Hire NodeJs Developer </Link>
                        </p>
                        <p>
                          <Link to="/hirelaravel">Hire Laravel Developer</Link>
                        </p>
                        <p>
                          <Link to="/hireshopify">Hire Shopify Developer</Link>
                        </p>
                        <p>
                          <Link to="/hirephp">Hire PHP Developer</Link>
                        </p>
                        <p>
                          <Link to="/hirewordpress">
                            Hire WordPress Developer
                          </Link>
                        </p>
                        <p>
                          <Link to="/hirereactnative">
                            Hire React Native Developer
                          </Link>
                        </p>
                        <p>
                          <Link to="/hireandroid">Hire Android Developer</Link>
                        </p>
                        <p>
                          <Link to="/hireios">Hire IOS Developer</Link>
                        </p>
                        <p>
                          <Link to="/hiresoftware">
                            Hire SoftWare Developer
                          </Link>
                        </p>
                        <p>
                          <Link to="/hiremobile">
                            Hire Mobile App Developer
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Nav>

              {/* <Nav.Link href="#pricing">
                {" "}
                <div className="profile">Branding</div>
              </Nav.Link> */}

              <Nav.Link href="ourportfolio">
                {" "}
                <div className="profile">Our Portfolio</div>
              </Nav.Link>
              {/* <Nav.Link href="/" >
                <div className="profile conta_div" >Contact Us</div>{" "}
              </Nav.Link> */}
            </Nav>

            <Nav>
              <Nav.Link>
                <div className="app_btn" onClick={handleShow}>
                  Book an Appointment
                </div>
              </Nav.Link>
            </Nav>

            <Nav>
              {/* <Nav.Link className="login_div ">
                <div className="main-Login">
                  Login
                  <div className="sub-login">
                    <p>
                      <Link to="/loginBuyer">Buyer</Link>
                    </p>
                    <p>
                      <Link to="/loginSeller">Seller </Link>
                    </p>
                  </div>
                </div>
              </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </ContainerFluid>
      </Navbar>

      <CustomModal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <div>
            {/* <Modal.Title >Book Here</Modal.Title> */}
            <div className="head_div">
              <span class="black-content">Book Here </span>

              <p style={{ marginBottom: "0px" }}>
                Please fill out all information correct
              </p>
            </div>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="time_heading">
            <span>Select Date & Time should be mandatory</span>
          </div>

          <div className="cal_div">
            <div className="select-wrapper">
              <TimezoneSelect
                value={selectedTimezone}
                onChange={setSelectedTimezone}
              />
            </div>

            <div>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                showTimeSelect
                dateFormat="Pp"
                className="cale_time"
              />
            </div>
          </div>

          <div className="form_div">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12">
                  <input type="text" placeholder="Name*"></input>
                </div>
                <div className="col-lg-6 col-md-12 col-12">
                  <input type="number" placeholder="Phone*"></input>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-6 col-md-12 col-12">
                  <input type="email" placeholder="Email Address*"></input>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-12 col-md-12 col-12">
                  {/* <label className="label_heading">
                    What services can we provide you?
                  </label> */}

                  <FormControl className="serv_div">
                    <Select
                      multiple
                      displayEmpty
                      value={personName}
                      onChange={handleChange}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Services</em>;
                        }

                        return selected.join(", ");
                      }}
                      MenuProps={MenuProps}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled value="">
                        <em></em>
                      </MenuItem>
                      {names.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          style={getStyles(name, personName, theme)}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-12">
                  <label className="label_heading">Share Project Details</label>
                  <br />
                  <textarea name="message" rows="5" cols="75"></textarea>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-lg-12">
                  <div className="sub_btn">
                    <button>Sumbit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </CustomModal>
    </Root>
  );
}

const CustomModal = styled(Modal)`
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #f3f3f3;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
  }
  .head_div {
    .black-content {
      font-size: 25px;
      font-weight: bold;
      line-height: 1.3em;
    }
    .colored-content {
      font-size: 35px;
      font-weight: bold;
      line-height: 1.3em;
      background: ${secGradient};
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .time_heading {
    display: flex;
    justify-content: center;
    span {
      font-size: 19px;
      font-weight: 600;
      line-height: 1.3em;
    }
  }
  .cal_div {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 15px;
    input.cale_time {
      padding: 10px;
      border-radius: 5px;
      background-color: #f3f3f3;
      border: 1px solid #dadada;
      color: #6b7996;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      color: #6b7996;
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
      border-radius: 0.3rem;
      background-color: #13c9df;
      color: #fff;
    }

    .css-1fdsijx-ValueContainer {
      padding: 5px 8px;
    }
    .css-13cymwt-control {
      margin-top: 1px;
      /* width: 225px; */
      background-color: #f3f3f3;
    }

    .css-qbdosj-Input {
      color: #6b7996 !important;
    }

    .mon_head {
      font-size: 20px;
      font-weight: 500;
    }
    .main {
      width: 20rem;
      padding: 0.7rem;
      margin-left: 1rem;
      border: 1px solid #dadada;
    }
    .header {
      display: flex;
      margin-bottom: 0.7rem;
      justify-content: space-between;
      align-items: center;
    }
    .header button {
      font-size: 1.4rem;
      cursor: pointer;
      color: #13c9df !important;
      border: 1px solid transparent !important;
    }
    .body {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      gap: 5px;
    }
    .box {
      height: 2rem;
      background-color: #f0f0f0;
      border: 1px solid #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .box.empty {
      background-color: #e0e0e0;
      cursor: default;
    }
    .box.selected {
      background-color: #13c9df;
      color: white;
    }
  }

  .form_div {
    input[type="text"] {
      border: 1px solid #dadada;
      height: 50px;
      width: 100%;
      background-color: #f3f3f3;
      padding-left: 10px;
      border-radius: 5px;
    }

    input[type="text"] {
      border: 1px solid #dadada;
      height: 50px;
      width: 100%;
      background-color: #f3f3f3;
      padding-left: 10px;
      border-radius: 5px;
    }

    input[type="number"] {
      border: 1px solid #dadada;
      height: 50px;
      width: 100%;
      background-color: #f3f3f3;
      padding-left: 10px;
      border-radius: 5px;
      &:hover {
        border: 1px solid #000000;
      }
    }

    input[type="email"] {
      border: 1px solid #dadada;
      height: 50px;
      width: 100%;
      background-color: #f3f3f3;
      padding-left: 10px;
      border-radius: 5px;
      &:hover {
        border: 1px solid #000000;
      }
    }

    textarea {
      width: 100%;
      background: #f3f3f3;
      border: 1px solid #dadada;
    }
    .sub_btn {
      display: flex;
      justify-content: center;
      button {
        font-size: 14px;
        color: white;
        background-color: #13c9df;
        text-transform: uppercase;
        padding: 13px 81px;
        border: 0;
        letter-spacing: 3px;
        border-radius: 50px;
      }
    }

    .serv_div {
      width: 100%;
    }
    /* .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    border: 1px solid #fffbfb!important;
 
} */
  }

  @media (max-width: 567px) {
    .form_div input[type="text"] {
      margin-bottom: 15px;
    }
  }
  @media (min-width: 567px) and (max-width: 992px) {
    .form_div input[type="text"] {
      margin-bottom: 15px;
    }
  }
`;

const Root = styled.section`
  border-bottom: 1px solid lightgray;
  flex-direction: column;
  display: flex;
  height: auto;
  background-color: #fff;
  box-shadow: 1px 1px 5px 1px lightgray;
  /* margin-bottom: 80px; */
  /* padding: 10px 0px 0px; */
  /* position: fixed;
  z-index: 1111; */

  nav.bg-body-tertiary.nav-menu.navbar.navbar-expand-lg.navbar-light {
    padding: 10px;
  }

  div#responsive-navbar-nav {
    display: flex;
    justify-content: space-evenly;
  }

  .nav-menu {
    width: 100vw;
  }
  a {
    margin: 0px;
    text-decoration: none;
  }
  img {
    width: 220px;
    padding: 0px 15px;
    mix-blend-mode: multiply;
  }
  .profileLogin {
    width: 100px;
  }
  .profile {
    display: flex;
    font-size: 15px;
    color: #000000;
    padding: 5px;
    margin: 5px;
    gap: 7px;
    align-items: center;
    position: relative;
    cursor: pointer;
    &:hover {
      color: ${primaryColor};
    }
    a {
      color: #000;
      text-decoration: none;
      &:hover {
        color: #13c9df;
      }
    }

    > div {
      font-size: 14px;
      svg {
        width: 13px;
        height: 13px;
      }
    }
    .option_list_list {
      left: -23px;
      top: 100%;
      position: absolute;
      .mini_option_list {
        padding: 10px;

        p {
          margin: 0;
          &:hover {
            background-color: #0093ab;
          }
        }
      }
    }
    .option_list {
      left: -23px;
      top: 100%;
      position: absolute;
    }
    .option_list2 {
      right: -210px;
      top: 0;
      position: absolute;
    }
    .option_list3 {
      right: -170px;
      top: 50%;
      position: absolute;
    }
    .option_list4 {
      right: -170px;
      top: 70%;
      position: absolute;
    }

    .option_list,
    .option_list_list,
    .option_list2,
    .option_list3,
    .option_list4 {
      z-index: 1111;
      text-decoration: none;
      min-width: 220px;
      border: 1px solid ${primaryColor};
      background-color: ${primaryColor};
      color: #fff;
      p {
        margin: 0;
        padding: 15px;
        &:hover {
          background-color: #0093ab;
        }
      }
      a {
        text-decoration: none;
        color: white;
      }
    }

    .off {
      display: none;
    }
  }

  .no_pop {
    display: none;
  }

  ul.closed {
    display: none;
  }

  ul.open {
    display: block;
    -webkit-overflow-scrolling-y: scroll;
  }

  .option_list {
    position: relative;
    display: inline-block;
  }

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: transparent;
    &:hover {
      border-color: transparent;
    }
  }

  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
    &:hover {
      border-color: transparent;
    }
  }

  a.login_div.nav-link {
    margin-left: -104px;
  }

  .serv_div {
    position: relative;
    color: #000;
  }

  .sub_ser_div {
    position: absolute;
    top: 30px;
    left: -66px;
    border: 1px solid transparent;
    padding: 20px;
    z-index: 10;
    width: 860px;

    background-color: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    .serv_megamenu_div {
      width: 100%;
      margin-left: 10px;
      display: flex;
      flex-wrap: wrap;
      .mega_div {
        display: flex;
        gap: 10px;
        flex-direction: column;
        flex: 1;

        .first-content {
          font-size: 13px;
          font-weight: 500;
          line-height: 1.3em;
          color: #13c9df;
        }

        p {
          margin-bottom: 0;
          a {
            color: #000;
            font-size: 12px;
            text-decoration: none;
            &:hover {
              color: #13c9df;
            }
          }
        }
      }
    }
  }
  /* .sub_ser_div {
    display: none;
    position: absolute;
    top: 30px;
    left: -66px;
    border: 1px solid transparent;
    padding: 20px;
    z-index: 10;
    width: 860px;

    background-color: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    .row {
      width: 100%;
      margin-left: 10px;
      .mega_div {
        display: flex;
        gap: 10px;
        flex-direction: column;

        .first-content {
          font-size: 13px;
          font-weight: 500;
          line-height: 1.3em;
          color: #13c9df;
        }

        p {
          margin-bottom: 0;
          a {
            color: #000;
            font-size: 12px;
            text-decoration: none;
            &:hover {
              color: #13c9df;
            }
          }
        }
      }
    }
  } */

  /* .serv_div:hover .sub_ser_div {
    display: block;
  } */

  .main-Login {
    position: relative;
    display: inline-block;
    color: #000;
  }

  .sub-login {
    display: none;
    position: absolute;
    top: 100%;
    left: -35px;
    /* background-color: white;
    border: 1px solid #ccc; */
    padding: 10px 10px 0px;
    z-index: 10;
  }

  .app_btn {
    font-size: 16px;
    color: white;
    background-color: #13c9df;
    padding: 12px 25px;
    border: 0;
    border-radius: 50px;
  }

  .main-Login:hover .sub-login {
    display: block;
  }

  .sub-login p a {
    color: #fff;
  }

  /* .sub-login {
    background-color: #13c9df !important;
  } */

  .sub-login p {
    padding: 5px 20px;
    background: ${secGradient};
    border-radius: 50px;
    margin-bottom: 10px;
  }

  @media (max-width: 567px) {
    a.login_div.nav-link {
      margin-left: 13px;
    }

    img {
      width: 120px;
      padding: 0px 0px;
      mix-blend-mode: multiply;
    }

    .app_btn{
      width:220px;
    }

    .serv_div {
      display: none;
    }

    .sub_ser_div {
      width: 100%;
      left: -2px;
      padding: 20px 10px;
    }

    .sub_ser_div .serv_megamenu_div .mega_div {
      width:50%;
      flex:unset;
      margin-top:20px;
      /* align-items: center; */
    }

    .sub_ser_div .serv_megamenu_div .mega_div .first-content {
      font-size: 11px;
    }

    .sub_ser_div .serv_megamenu_div .mega_div p a {
      font-size: 10px;
    }

    .sub_ser_div .serv_megamenu_div {
      margin-left: 0;
    }
  }

  @media (min-width: 567px) and (max-width: 992px) {
    a.login_div.nav-link {
      margin-left: 13px;
    }

    img {
      width: 150px;
      padding: 0px 0px;
      mix-blend-mode: multiply;
    }

    .app_btn {
      width: 220px;
    }

    .serv_div {
      display: none;
    }

    .sub_ser_div {
      left: 0px;
      width: unset;
    }
  }
`;
